import React from "react"
import styled from "styled-components"
import theme from "../theme"

const TextContainer = styled.div`
  p,
  ul,
  li {
    line-height: 1.5;
    font-size: calc(18 / 16 * 1rem);
    font-weight: 300;

    @media (min-width: ${theme.breakpoint.large}) {
      font-size: calc(24 / 20 * 1.25rem);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: calc(24 / 16 * 1rem);
    font-weight: normal;

    @media (min-width: ${theme.breakpoint.large}) {
      font-size: calc(32 / 16 * 1rem);
    }
  }

  a {
    color: ${theme.color.text};
    text-decoration: none;
    position: relative;
  }

  a:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    border: 0.1px solid ${theme.color.line};
    transition: border-color 0.25s linear;
  }

  a:hover:after {
    border-color: ${theme.color.text};
  }

  text-align: ${(props) => (props.left ? "left" : false)};
`

const Text = (props) => {
  return <TextContainer {...props}>{props.children}</TextContainer>
}

export default Text
