import React from "react"
import styled, { css } from "styled-components"
import theme from "../theme"

const HeadlineContainer = styled.h2`
  margin: 0.5em 0;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: normal;

  ${(props) =>
    props.primary
      ? css`
          font-size: calc(34 / 16 * 1rem);

          @media (min-width: ${theme.breakpoint.large}) {
            font-size: calc(90 / 16 * 1rem);
          }
        `
      : css`
          font-size: calc(17 / 16 * 1rem);

          @media (min-width: ${theme.breakpoint.large}) {
            font-size: calc(45 / 16 * 1rem);
          }
        `}
`

const Headline = (props) => {
  return (
    <HeadlineContainer primary={props.primary}>{props.text}</HeadlineContainer>
  )
}

export default Headline
