import NeusaTTF from "./fonts/5500225/02bd5c79-5033-4132-99e0-fba71ed8ba75.ttf"
import NeusaWOFF from "./fonts/5500225/c4708552-f6b3-4e07-889d-022dd0af3f60.woff"
import NeusaEOT from "./fonts/5500225/17591406-26d9-4b78-ace4-086a6c388994.eot"
import NeusaWOFF2 from "./fonts/5500225/deab4579-46c8-41b8-ae75-054128ab66d7.woff2"

const theme = {
  color: {
    text: "rgb(255, 255, 255)",
    bg: {
      dark: "rgb(0, 0, 0)",
      light: "rgb(51, 51, 51)",
    },
    line: "#403F41",
  },
  font: {
    primary: {
      ttf: NeusaTTF,
      woff: NeusaWOFF,
      woff2: NeusaWOFF2,
      eot: NeusaEOT,
    },
  },
  breakpoint: {
    large: "64rem",
  },
}

export default theme
