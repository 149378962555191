import React from "react"
import styled from "styled-components"
import theme from "../theme"
import { Link } from "gatsby"

const FooterContainer = styled.div`
  margin: 1rem;
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 0.1px solid ${theme.color.line};

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin: 0.5em;
  }

  a {
    color: ${theme.color.text};
    text-decoration: none;
  }
`

const Footer = (props) => {
  let year = new Date().getFullYear()

  return (
    <FooterContainer>
      <ul>
        {props.navigation.map((navigationItem, index) => (
          <li key={index}>
            <Link to={navigationItem.to}>{navigationItem.name}</Link>
          </li>
        ))}
      </ul>
      {props.copyright ? (
        <ul>
          <li>
            <a href="https://www.hagenhansen.de">
              © {year} {props.copyright}
            </a>
          </li>
        </ul>
      ) : (
        false
      )}
    </FooterContainer>
  )
}

export default Footer
