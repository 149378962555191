import React from "react"
import styled from "styled-components"

const BlockContainer = styled.div`
  min-height: calc(100vw / 16 * 9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const BlockContent = styled.div`
  padding: 2rem;
  max-width: 80vw;
  text-align: center;
`

const Block = (props) => {
  return (
    <BlockContainer id={props.id}>
      <BlockContent>{props.children}</BlockContent>
    </BlockContainer>
  )
}

export default Block
