import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"
import theme from "../theme"

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 3;

  input {
    display: none;
  }

  label {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: auto;
    }

    svg #line-middle-1,
    svg #line-middle-2,
    svg #line-top,
    svg #line-bottom {
      transition: all 0.25s linear;
      transform: rotate(0);
      transform-origin: 24px 24px;
    }

    ${(props) =>
      props.state
        ? css`
            svg #line-middle-1 {
              transform: rotate(-45deg);
            }

            svg #line-middle-2 {
              transform: rotate(45deg);
            }

            svg #line-top,
            svg #line-bottom {
              opacity: 0;
            }
          `
        : false}
  }
`

const HeaderOverlay = styled.nav`
  position: absolute;
  left: 0;
  top: 0em;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: ${(props) => (props.state ? "all" : "none")};
  transition: opacity 0.25s linear;
  background-color: ${theme.color.bg.dark};
  opacity: ${(props) => (props.state ? 1 : 0)};

  > * {
    transition: opacity 0.25s linear;
    opacity: ${(props) => (props.state ? 1 : 0)};
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 2em 0;
    display: flex;
    justify-content: center;
  }

  a {
    color: ${theme.color.text};
    text-decoration: none;
    font-size: calc(24 / 16 * 1rem);
    text-transform: uppercase;

    @media (min-width: ${theme.breakpoint.large}) {
      font-size: calc(45 / 16 * 1rem);
    }
  }
`

class Header extends React.Component {
  state = {
    isChecked: false,
  }
  toggle = () => {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }))
  }
  render() {
    const { isChecked } = this.state
    const { navigation } = this.props

    return (
      <HeaderContainer state={isChecked}>
        <input id="menu" type="checkbox" defaultChecked={isChecked} />
        <label
          onClick={this.toggle}
          onKeyPress={this.toggle}
          htmlFor="menu"
          role="presentation"
        >
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
            <g
              id="menu"
              stroke="none"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="square"
            >
              <path d="M6,12 L42,12" id="line-top" stroke={theme.color.text} />
              <path
                d="M6,36 L42,36"
                id="line-bottom"
                stroke={theme.color.text}
              />
              <path
                d="M6,24 L42,24"
                id="line-middle-2"
                stroke={theme.color.text}
                transform="translate(24.000000, 24.000000) rotate(-360.000000) translate(-24.000000, -24.000000) "
              />
              <path
                d="M6,24 L42,24"
                id="line-middle-1"
                stroke={theme.color.text}
                transform="translate(24.000000, 24.000000) rotate(-360.000000) translate(-24.000000, -24.000000) "
              />
            </g>
          </svg>
        </label>

        <HeaderOverlay state={isChecked} navigation={navigation}>
          <ul>
            {navigation.map((navigationItem, index) => (
              <li key={index}>
                <a href={navigationItem.to} onClick={this.toggle}>
                  {navigationItem.name}
                </a>
              </li>
            ))}
          </ul>
        </HeaderOverlay>
      </HeaderContainer>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
