/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"

import theme from "../theme"

import Header from "../components/header"
import Footer from "../components/footer"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Neusa";
    font-style: normal;
    font-weight: normal;
    src: url(${theme.font.primary.eot}?#iefix);
    src: url(${theme.font.primary.eot}?#iefix) format("eot"), 
    url(${theme.font.primary.woff2}) format("woff2"), 
    url(${theme.font.primary.woff}) format("woff"), 
    url(${theme.font.primary.ttf}) format("truetype");
  }

  html, body {
    background-image: linear-gradient(to bottom, 
      ${theme.color.bg.dark},
      ${theme.color.bg.light} 75%,
      ${theme.color.bg.dark}
    );
    background-color: ${theme.color.bg.dark};
    background-attachment: fixed;
    font-family: "Neusa", sans-serif;
    color: ${theme.color.text};
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <GlobalStyle />
        <Header
          navigation={[
            { to: "/#", name: "Designbüro" },
            { to: "/#facts", name: "Facts" },
            { to: "/#design", name: "Design" },
            { to: "/#ecmmusicstore", name: "ECM Musicstore" },
            { to: "/#ausstellungen", name: "Ausstellungen" },
            { to: "/#kontakt", name: "Kontakt" },
          ]}
        />
        <main>{children}</main>
        <Footer
          navigation={[
            { to: "/impressum", name: "Impressum" },
            { to: "/datenschutz", name: "Datenschutz" },
            { to: "/ahrenshoop", name: "Ahrenshoop" },
            { to: "/avg", name: "AVG" },
          ]}
          copyright="Hagen Hansen"
        />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
